import { Button, Modal, Select, Spin, Steps } from "antd";
import useUpdatesQuarter from "../../hooks/useUpdatesQuarter";
import "./QuarterUpdatesModal.scss";
import ProceduresAPI from "../../api/ProceduresAPI";
import useApp from "../../hooks/useApp";

const QuarterUpdatesModal = (props: any) => {
  const {
    isUpdatesModalOpen,
    setIsUpdatesModalOpen,
    baseUrl,
    getAuthToken,
    onSelect,
  } = props;

  const updatesApi = ProceduresAPI({ baseUrl, getAuthToken });

  const {
    closeUpdatesModal,
    currentStep,
    setCurrentStep,
    goToNetCash,
	selectedPeip,
	onChangeSelectedPeip,
	onChangeQuarter,
	selectedQuarter,
	quartersList,
	generateFundFigures,
	loading
  } = useUpdatesQuarter({
    setIsUpdatesModalOpen,
    updatesApi,
    onSelect,
	isUpdatesModalOpen
  });

  const { peipList } = useApp({ baseUrl, getAuthToken });

  const steps = [
    {
      title: "Current Cash",
      content: (
        <>
          <p>
            Please make sure you have already completed the Net Cash by Vehicle.
            If not, please go to the{" "}
            <strong>Net Cash section and complete it</strong> or click the
            button below to go there.
          </p>
          <Button className="netCash-btn" type="primary" onClick={goToNetCash}>
            Go to Net Cash
          </Button>
        </>
      ),
    },
    {
      title: "Quaterly Reports",
      content: (
        <p>
          Please validate if all the Quarterly Reports were uploaded to the
          <strong> Document Management</strong> app. If not, please follow the
          steps below:
          <br />
          <ol>
            <li>
              Enter the following link:{" "}
              <a
                href="https://management.qualitasfunds.com/QualitasWorld/DocumentManagement/InvestorPortal"
                target="_blank"
                rel="noreferrer"
              >
                https://management.qualitasfunds.com/QualitasWorld/DocumentManagement/InvestorPortal
              </a>
            </li>
            <li>
              Upload the missing reports using the <strong>Upload File</strong>{" "}
              tool.
            </li>
            <li>Ensure you check the Quarterly Reports box.</li>
          </ol>
        </p>
      ),
    },
    {
      title: "Fund Figures",
      content: (
        <>
          When the previous steps are completed, please press this button to
          start the Fund Figures process. This process may take a while. 
          <br />
          <div className="status-fundFigures">
            <Select
              placeholder="Select a Quarter"
              style={{ width: 220, marginRight: 10 }}
			  value={selectedQuarter}
			  onChange={onChangeQuarter}
			>
				{quartersList.map((quarter: any) => {
					return <Select.Option key={quarter.value} value={quarter.value}>{quarter.label}</Select.Option>}
				)}
			</Select>
            <Select
              placeholder="Select a Peip"
              style={{ width: 220, marginRight: 10 }}
			  value={selectedPeip}
			  onChange={onChangeSelectedPeip}
			>
				{peipList.map((peip: any) => {
					return <Select.Option key={peip.idPeip} value={peip.idPeip}>{peip.peipNameLong}</Select.Option>}
				)}
			</Select>
            <Button
              type="primary"
              onClick={generateFundFigures}
            >
              Start Fund Figures
            </Button>
          </div>
        </>
      ),
    },
    {
      title: "Investor Portal",
      content: (
        <>
          <p>
            Check the Investor Portal to validate the information is updated
            correctly. If all looks good, <strong>you are done!</strong>. If
            there's an issue, please contact the IT department. You can press
            the Done button below.
          </p>
          <img
            src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/icons/success.png"
            alt="success"
            className="success-img"
          />
        </>
      ),
    },
  ];

  const items = steps.map((item: any) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <Modal
      title="Quarter Updates"
      open={isUpdatesModalOpen}
      centered
      footer={null}
      width={800}
      onCancel={closeUpdatesModal}
    >
      <p>
        Please follow these steps when updating the quarter information in the
        Investor Portal:
      </p>
      <div className="updates-container">
        <Steps current={currentStep} items={items} />
		<Spin spinning={loading}>
		   <div className="steps-content">{steps[currentStep].content}</div>
		</Spin>
        <div>
          {currentStep < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => setCurrentStep(currentStep + 1)}
            >
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" onClick={closeUpdatesModal}>
              Done
            </Button>
          )}
          {currentStep > 0 && (
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Previous
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default QuarterUpdatesModal;
