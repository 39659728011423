import { notification } from 'antd'
import { useEffect, useState } from 'react'

const useProcedures = (props: any) => {
	const { setIsProceduresModalOpen, proceduresApi, isProceduresModalOpen} = props

	const [quartersList, setQuartersList] = useState<any[]>([])
	const [selectedQuarter, setSelectedQuarter] = useState<string | undefined>(undefined)
	const [loading, setLoading] = useState<boolean>(false)
	const [selectedPeip, setSelectedPeip] = useState<string | undefined>(undefined)
	const [status, setStatus] = useState<{
		status: string,
		peip: string | null,
		registries: any,	
	}>({
		status: 'Finished',
		peip: null,
		registries: 0
	}) 

	useEffect(() => {
		if (!isProceduresModalOpen) return;
		fillQuarters()
		checkFundFigureStatus()
	}, [isProceduresModalOpen])

	useEffect(() => {
		if(status.status === 'In Progress'){
			const interval = setInterval(() => {
				checkFundFigureStatus()
			}, 3000)
			return () => clearInterval(interval)
		}
	}, [status.status])
	
	const checkFundFigureStatus = () => {
		proceduresApi.checkTiresStatus().then((res: any) => {
			if(res.success){
				setStatus(res.data)
			}else{
				setStatus({status: 'finished', peip: 'Error', registries: 0})
			}
		})
	}

	const closeProceduresModal = () => {
		setIsProceduresModalOpen(false)
		setSelectedPeip(undefined)
	}

	const onChangeQuarter = (value: string) => {
		setSelectedQuarter(value)
	}

	const onChangePeip = (value: string) => {
		setSelectedPeip(value)
	}

	const startProcess = async(process: string) => {
		setLoading(true);
		await proceduresApi.executeProcedures(selectedQuarter, process).then((res:any) => {
			if(res?.success){
				setLoading(false)
				notification.success({ message: 'Process started successfully' })
			}else{
				setLoading(false)
				notification.error({ message: 'Error starting the process' })
			}
		})
	}
	const generateTires = async() => {
		proceduresApi.generateTires(selectedQuarter, selectedPeip)
		setStatus({status: 'In Progress', peip: '...', registries: 0})
	};

	const generateFundFigures = () => {
		proceduresApi.generateFundFigures(selectedPeip, selectedQuarter, false);
		setStatus({status: 'In Progress', peip: '...', registries: 0});
	};

	const fillQuarters = () => {
		const dates = ['03-31', '06-30', '09-30', '12-31']
		const currentYear = new Date().getFullYear()
		const lastYear = currentYear - 1
		const quarters = []
		for (let i = 0; i < 2; i++) {
			for (let j = 0; j < 4; j++) {
				quarters.push({
					value: `${i === 0 ? lastYear : currentYear}-${dates[j]}`,
					label: ` Q${j + 1} ${i === 0 ? lastYear : currentYear}`
				})
			}
		}
		setQuartersList(quarters)
	};


  	return {
		closeProceduresModal,
		onChangeQuarter,
		startProcess,
		quartersList,
		selectedQuarter,
		loading,
		onChangePeip,
		selectedPeip,
		generateTires,
		status,
		generateFundFigures
  	}
};

export default useProcedures